<template>
    <v-container>
        <AdBTWR v-if="!user.subscribed"/>
        <h1 class="page-title font-weight-light">Help Topics</h1>
    </v-container>
</template>

<script>
    import AdBTWR from '@/components/ads/BTWR.vue';
    import {useTitle} from '@vueuse/core';

    export default {
        props: ['user'],

        components: {AdBTWR},

        mounted() {
            useTitle('Help Center - myGMRS.com');
        }
    }
</script>
